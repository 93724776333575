import React, { useState, useEffect, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import styles from "./ParentSignInForm.module.scss";
import { Modal } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import {
  checkValidString,
  validateString,
  validateName,
} from "../../../utils/validations";
import { updateOnboardigUserProfile } from "../../../api/onboarding";
import {
  setUserData as updateUserData,
  removeUserProfileImage,
} from "../../../redux/reducers/userReducer";
import {
  convertObjectToFormData,
  getCapitalizedHeader,
  setMSClarityTagsForUserProfileData,
} from "../../../utils/common";
import toastify from "../../../utils/toast";
import MuiTextbox, {
  MuiTextboxModifier,
} from "../../atoms/MuiTextbox/MuiTextbox";
import { updateProfileImage } from "../../../api/profile";

const cx = createModuleStyleExtractor(styles);

const ParentSignInForm = ({ showModal = false, onSubmit }) => {
  // Loader States
  const [isUpdatingProfileImage, setIsUpdatingProfileImage] = useState(false);

  let formRef = useRef();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userState);
  const { user = {} } = userState;

  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUserDataChange = (key, value) => {
    setUserData({
      ...userData,
      [key]: value,
    });
  };

  useEffect(() => {
    if (formRef.current) formRef.current.validateForm();
  }, [userData]);

  useEffect(() => {
    setUserData({
      first_name: checkValidString(user.first_name) ? user.first_name : "",
      last_name: checkValidString(user.last_name) ? user.last_name : "",
      user_profile: user.profile_image_url ?? "",
    });

    setMSClarityTagsForUserProfileData(user);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const response = await updateOnboardigUserProfile(
      user.id,
      convertObjectToFormData("user", userData)
    );

    if (response.success && response.data) {
      const { users = {} } = response.data;
      dispatch(updateUserData(users));
      toastify(response.message);
      onSubmit();
    } else {
      setIsSubmitting(false);
      toastify(response.message);
    }
  };

  const handleProfileImageRemove = async (status) => {
    if (status) {
      setIsUpdatingProfileImage(true);

      let formData = new FormData();
      formData.append("remove_user_profile", true);

      const response = await updateProfileImage(formData);
      if (response?.success) {
        handleUserDataChange("user_profile", ""); // Update form state
        setIsUpdatingProfileImage(false); // Disable loader
        toastify(response.message ?? "Profile image removed"); // Show toast message
        dispatch(removeUserProfileImage()); // Update user redux state
      } else {
        setIsUpdatingProfileImage(false);
        toastify("Oops! something went wrong. Please try again");
      }
    }
  };

  return (
    <div className={cx("m-parent-sign-in-form")}>
      <Modal show={showModal} hideClose centerAlign={false}>
        <Formik
          innerRef={formRef}
          initialValues={{
            first_name: "",
            last_name: "",
            user_profile: "",
          }}
          validate={() => {
            let errors = {};
            // if (
            //   validateString(user.user_profile) &&
            //   validateFile(userData.user_profile)
            // ) {
            //   errors.user_profile = "Profile picture is required";
            // }
            if (validateString(userData.first_name)) {
              errors.first_name = "First name is required";
            } else if (validateName(userData.first_name)) {
              errors.first_name = "First name is not valid";
            }
            if (validateString(userData.last_name)) {
              errors.last_name = "Last name is required";
            } else if (validateName(userData.last_name)) {
              errors.last_name = "Last name is not valid";
            }
            setErrors(errors);
            return errors;
          }}
          onSubmit={(values) => {
            // same shape as initial values
            // console.log(values);
            handleSubmit();
          }}
        >
          <Form>
            <ModalBody>
              <h2>Finalize your profile to get started.</h2>

              <div className={cx("m-parent-sign-in-form__formfields")}>
                <Field name="user_profile">
                  {({ field, meta }) => (
                    <div
                      className={cx(
                        "m-parent-sign-in-form__formfields__user-profile"
                      )}
                    >
                      <label>Upload a profile picture (optional)</label>
                      <FileUpload
                        {...field}
                        name={"user_profile"}
                        userName={user.name}
                        maxFileSize={5}
                        defaultBackgroundImage={userData.user_profile}
                        onSelectSuccess={(file) =>
                          handleUserDataChange("user_profile", file)
                        }
                        onRemoveSuccess={(status) =>
                          handleProfileImageRemove(status)
                        }
                        loader={isUpdatingProfileImage}
                        cropperAlign="start"
                      />
                    </div>
                  )}
                </Field>
                <Field name="first_name">
                  {({ field, meta }) => (
                    <div
                      className={cx("m-parent-sign-in-form__formfields--group")}
                    >
                      <MuiTextbox
                        {...field}
                        value={userData.first_name}
                        onChange={(e) =>
                          handleUserDataChange(
                            "first_name",
                            getCapitalizedHeader(e.target.value)
                          )
                        }
                        name={"first_name"}
                        label="Your first name*"
                        placeholder={"Enter first name"}
                        error={meta.touched && meta.error}
                        modifier={MuiTextboxModifier.CENTER_ALIGN}
                      />
                    </div>
                  )}
                </Field>
                <Field name="last_name">
                  {({ field, meta }) => (
                    <div
                      className={cx("m-parent-sign-in-form__formfields--group")}
                    >
                      <MuiTextbox
                        {...field}
                        value={userData.last_name}
                        onChange={(e) =>
                          handleUserDataChange("last_name", e.target.value)
                        }
                        name={"last_name"}
                        label="Your last name*"
                        placeholder={"Enter last name"}
                        error={meta.touched && meta.error}
                        modifier={MuiTextboxModifier.CENTER_ALIGN}
                      />
                    </div>
                  )}
                </Field>
              </div>
            </ModalBody>
            <ModalFooter className={cx("m-parent-sign-in-form__footer")}>
              <Button
                type="submit"
                isSubmitting={isSubmitting}
                disabled={Object.keys(errors || {})?.length > 0}
              >
                Continue
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </Modal>
    </div>
  );
};
export default memo(ParentSignInForm);

import React, { useRef, memo, useEffect, useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFetchConversation } from "../../../hooks/useFetchConversation";
import { getChatPreviewByConversationId } from "../../../redux/reducers/chat/selectors";
import {
  detectDispalyMode,
  generateDisplayName,
  extractFirstName,
} from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import { CircularAvatar } from "../../atoms/CircularAvatar/CircularAvatar";
import { UserProfileCard } from "../../atoms/UserProfileCard/UserProfileCard";
import EmptyChatView from "../EmptyChatView/EmptyChatView";
import GroupProfile from "../GroupProfile/GroupProfile";
import ChatTextBox from "../ChatTextBox/ChatTextBox";
import styles from "./InitChat.module.scss";
import Messages from "../Messages/Messages";
import { useDeleteChat } from "../../../hooks/useDeleteChat";
import { defaultState, reducer } from ".";
import {
  bulkUpdate,
  toggleMuteStatus,
  resetPreviousConversationMessages,
  setIsMessageSending,
  webSocketActionRecevied,
} from "../../../redux/reducers/chat/reducers";
import {
  muteOrUnmuteChat,
  sendChatMessageAttachmentAPI,
} from "../../../api/chat";
import ConfirmRemoveChat from "../ConfirmRemoveChat/ConfirmRemoveChat";
import MuteChat from "../MuteChat/MuteChat";
import ReportChat from "../ReportChat/ReportChat";
import ChatOptions from "../ChatOptions/ChatOptions";
import { RequestMeeting } from "../RequestMeeting/RequestMeeting";
import toastify from "../../../utils/toast";

import ChevronLeft from "../../../assets/Icons/ChevronLeft";
import elipsis from "../../../assets/images/f-elipsis.svg";
import close from "../../../assets/images/f-close.svg";

const cx = createModuleStyleExtractor(styles);

const InitChat = ({ isMobile }) => {
  const dispatch = useDispatch();
  const [showRequestMeetingModal, setShowRequestMeetingModal] = useState(false);
  const [state, _dispatch] = useReducer(reducer, defaultState);
  const { chatActionOptions, deleteChat, show } = state;
  const callbackRef = useRef();

  const {
    activeConversation,
    previousActiveConversation,
    isConversationView,
    isProfileView,
    chats,
    isChatsLoaded,
    isChatsLoading,
    attachment,
    isFullChatMode,
    conversations,
  } = useSelector((store) => store.chatStore);
  const preview = useSelector((store) =>
    getChatPreviewByConversationId(store.chatStore, activeConversation)
  );
  const { conversationChannel } = useSelector((store) => store.webSocketStore);
  const { user, selectedSchool } = useSelector((store) => store.userState);

  useDeleteChat(deleteChat.confirm, deleteChat.id, () => {
    _dispatch({
      type: "bulkUpdate",
      payload: {
        show: false,
        deleteChat: { confirm: false, id: null },
        showConfirmRemoveChat: false,
        isDeleteChatSubmitting: false,
      },
    });
  });

  useEffect(() => {
    // Close options menu when conversation changes
    _dispatch({ type: "modalShow", payload: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation]);

  useEffect(() => {
    // Clear previous active conversation's messages
    dispatch(resetPreviousConversationMessages());
  }, [previousActiveConversation, dispatch]);

  const { conversation, loading, fresh } = useFetchConversation();
  const container = useRef();

  const { receiver_id, mute_chat } = preview || {};

  const isUserDeactivated =
    preview && preview?.receiver_id !== "" && preview?.user_deleted;

  const handleSwipeOperation = (state) => {
    dispatch(bulkUpdate({ ...state }));
  };

  const handleBackOperation = (e) => {
    e?.preventDefault && e.preventDefault();
    handleSwipeOperation({
      isChatPreview: true,
      isConversationView: false,
    });
  };

  const handleProfileClick = (e) => {
    e?.preventDefault && e.preventDefault();
    handleSwipeOperation({
      isChatPreview: false,
      isConversationView: false,
      isProfileView: true,
    });
  };

  const handleRequestMeeting = () => {
    setShowRequestMeetingModal(true);
  };

  const handleToggleModal = (key) => {
    _dispatch({
      type: "updateState",
      key,
      value: !state[key],
    });
  };
  const handleOnSuccessDelete = async () => {
    _dispatch({
      type: "bulkUpdate",
      payload: {
        deleteChat: {
          confirm: true,
          id: activeConversation,
        },
        isDeleteChatSubmitting: true,
      },
    });
  };

  const handleOnSuccessMute = async () => {
    _dispatch({ type: "bulkUpdate", payload: { isMuteChatSubmitting: true } });
    const meta = await muteOrUnmuteChat(activeConversation);
    _dispatch({ type: "bulkUpdate", payload: { isMuteChatSubmitting: false } });
    if (meta.success) {
      dispatch(toggleMuteStatus({ conversationId: activeConversation }));
      _dispatch({
        type: "bulkUpdate",
        payload: {
          showConfirmMuteChat: false,
        },
      });
    }
  };

  const handleOnSuccessReport = async () => {
    _dispatch({
      type: "bulkUpdate",
      payload: {
        showConfirmReportChat: false,
      },
    });
  };

  const handleChatAction = (id) => {
    _dispatch({ type: "modalShow", payload: false });
    if (id === 0) handleRequestMeeting();
    else if (id === 1) handleToggleModal("showConfirmRemoveChat");
    else if (id === 2) handleToggleModal("showConfirmMuteChat");
    else handleToggleModal("showConfirmReportChat");
  };

  /**
   * Trigger when message is submitted
   * @param {*} content
   * @param {*} attachment
   */
  const onSubmit = async (
    content,
    attachment = null,
    parent_message_id = null
  ) => {
    const { chat } = conversation || {};

    if (attachment) {
      const response = await sendChatMessageAttachmentAPI(
        chat?.conversation_window_id,
        attachment.content,
        content
      );
      dispatch(setIsMessageSending(false));
      if (response.success && response.data) {
        dispatch(webSocketActionRecevied({ broadcastData: response?.data }));
      } else {
        toastify(response.message, "error");
      }
    } else {
      const { group_details } = conversation || {};
      const { conversation_id } = chat || {};
      const { conversation_window_id } = group_details || {};
      const body = {
        content,
        ...(receiver_id && {
          receiver_id,
          conversation_window_id: chat?.conversation_window_id || null,
        }),
        ...(!receiver_id &&
          conversation_window_id && { conversation_window_id }),
        ...(attachment && {
          shared_file: attachment.content,
          file_name: attachment.name,
        }),
        school_id: selectedSchool?.id ? selectedSchool.id : user.school_id,
        admission_portal: true,
        ...(conversation_id && { conversation_id }),
        ...(parent_message_id ? { parent_message_id } : {}),
      };
      conversationChannel.create(body);
      if (callbackRef?.current) {
        callbackRef.current();
      }
    }
  };

  useEffect(() => {
    _dispatch({
      type: "chatActionOptions",
      flag: mute_chat,
    });
  }, [mute_chat]);

  const generateConversationTitle = () => {
    return preview?.group_user_details && preview?.group_user_details.length > 0
      ? "Message with " + preview?.title
      : "Message with " +
        conversations[activeConversation]?.user_info?.user_type?.includes(
          "Ambassador"
        )
      ? extractFirstName(preview?.title)
      : generateDisplayName(preview?.title ?? "");
  };

  return (isChatsLoaded && !isChatsLoading && !chats) || chats?.length === 0 ? (
    <EmptyChatView />
  ) : (
    <div className={cx("init-chat-container")}>
      <ReportChat
        show={state?.showConfirmReportChat}
        onClose={() => handleToggleModal("showConfirmReportChat")}
        onSuccess={handleOnSuccessReport}
      />
      <ConfirmRemoveChat
        show={state?.showConfirmRemoveChat}
        onClose={() => handleToggleModal("showConfirmRemoveChat")}
        onSuccess={handleOnSuccessDelete}
        loading={state?.isDeleteChatSubmitting}
      />
      <MuteChat
        show={state?.showConfirmMuteChat}
        loading={state?.isMuteChatSubmitting}
        onClose={() => handleToggleModal("showConfirmMuteChat")}
        onSuccess={handleOnSuccessMute}
        isMute={mute_chat}
      />
      {conversations[activeConversation]?.user_info && (
        <RequestMeeting
          show={showRequestMeetingModal}
          handleClose={() => setShowRequestMeetingModal(false)}
          user={conversations[activeConversation].user_info}
        />
      )}

      <div
        className={cx([
          "chats-section-wrapper",
          isFullChatMode && !isMobile ? "chats-section-wrapper__full" : "",
        ])}
        style={{
          display: detectDispalyMode(isMobile, isConversationView),
        }}
      >
        <div className={cx("message-section")}>
          {show && (
            <ChatOptions
              chatActionOptions={chatActionOptions}
              handleChatAction={handleChatAction}
            />
          )}
          <div className={cx("message-section__top-bar")}>
            <span className={cx("message-section__close")}>
              <ChevronLeft
                style={{ width: "28px" }}
                onClick={handleBackOperation}
              />
            </span>
            <h4>{generateConversationTitle()}</h4>
            {preview && (
              <div className={cx("chat-user-avatar")}>
                <CircularAvatar
                  src={preview?.user_image}
                  size={40}
                  round
                  name={preview?.title || "Interstride User"}
                  onClick={handleProfileClick}
                />
              </div>
            )}
            <div className={cx("chat-user-menu-icon")}>
              <div className={cx("chat-user-menu-icon-v3-wrapper")}>
                {state.show ? (
                  <img
                    src={close}
                    width="16"
                    onClick={() =>
                      _dispatch({ type: "modalShow", payload: false })
                    }
                    alt="close"
                  />
                ) : (
                  <img
                    src={elipsis}
                    width="24"
                    onClick={() =>
                      _dispatch({ type: "modalShow", payload: true })
                    }
                    alt="show"
                  />
                )}
              </div>
            </div>
          </div>

          <div
            id="chat-container__interstride"
            className={cx([
              "chat-container",
              ...(isUserDeactivated ? ["chat-container__deactivated"] : []),
              ...(attachment ? "AttachmentAdded" : ""),
            ])}
          >
            <Messages
              loading={loading || fresh}
              messages={conversation?.messages || []}
              generateConversationTitle={generateConversationTitle}
              ref={container}
            />
          </div>
          <div style={{ position: "relative" }}>
            {isUserDeactivated && (
              <div className={cx("divider")}>
                <span className={cx("date")}>
                  {" "}
                  This user is no longer active{" "}
                </span>
              </div>
            )}
            {!loading && !isUserDeactivated && (
              <ChatTextBox
                ref={callbackRef}
                disabled={
                  preview &&
                  preview?.receiver_id !== "" &&
                  preview?.user_deleted
                }
                className={
                  conversation?.messages && conversation?.messages.length > 0
                    ? "start-chat-textbox"
                    : ""
                }
                styles={
                  conversation?.messages &&
                  conversation?.messages.length > 0 && {
                    height: "80px",
                  }
                }
                onSubmit={onSubmit}
              />
            )}
          </div>
        </div>
      </div>

      {/* ----------------------- User Profile --------------------------- */}
      <div
        className={cx([
          "nmessage-profile-section",
          isFullChatMode && !isMobile ? "fullchat-view-mode" : "",
        ])}
        style={{
          display: detectDispalyMode(isMobile, isProfileView),
        }}
      >
        {receiver_id ? (
          <UserProfileCard
            handleRequestMeeting={handleRequestMeeting}
            isUserDeactivated={isUserDeactivated}
          />
        ) : (
          <GroupProfile />
        )}
      </div>
    </div>
  );
};
export default memo(InitChat);

import React, { memo } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import GoogleIcon from "../../../assets/Icons/GoogleIcon.js";
import SocialIcon from "../SocialIcon/SocialIcon";

const AuthGoogle = ({ callback = () => {} }) => {
  const url = new URL(window.location.href);

  const login = useGoogleLogin({
    flow: "auth-code",
    onSuccess: callback,
    onError: (errorResponse) => console.log(errorResponse),
    scope: "openid profile email",
    redirect_uri: url.origin,
    use_fedcm_for_prompt: true,
  });

  return (
    <SocialIcon text={"Google"} icon={<GoogleIcon />} onClick={() => login()}>
      Google
    </SocialIcon>
  );
};
export default memo(AuthGoogle);

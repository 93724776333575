import React from "react";

function InfoIcon({ onClick = () => {}, variant = "text" }) {
  return (
    <>
      {variant === "text" ? (
        <svg
          style={{ cursor: "pointer" }}
          onClick={onClick}
          width="16"
          height="16"
          viewBox="0 0 17 17"
          role={"img"}
        >
          <g fill="none" fillRule="evenodd">
            <circle fill={"#607790"} cx="8.5" cy="8.5" r="8" />
            <path
              d="M9.22 13.5H7.38V6.14h1.84v7.36Zm-.88-8.32a.998.998 0 0 1-.728-.312.998.998 0 0 1-.312-.728c0-.277.104-.52.312-.728A.998.998 0 0 1 8.34 3.1c.277 0 .52.104.728.312.208.208.312.45.312.728 0 .277-.104.52-.312.728a.998.998 0 0 1-.728.312Z"
              fill="#FFF"
              fill-rule="nonzero"
            />
          </g>
        </svg>
      ) : (
        <svg
          width="17px"
          height="17px"
          viewBox="0 0 17 17"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Final-Design"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Jobs-Dashboard/New-Users"
              transform="translate(-206.000000, -145.000000)"
            >
              <g id="Search" transform="translate(40.000000, 140.000000)">
                <g
                  id="Icon/Information"
                  transform="translate(166.000000, 5.000000)"
                >
                  <circle
                    id="Oval"
                    fill="#000C3D"
                    cx="8.5"
                    cy="8.5"
                    r="8"
                  ></circle>
                  <path
                    d="M9.22,13.5 L7.38,13.5 L7.38,6.14 L9.22,6.14 L9.22,13.5 Z M8.34,5.18 C8.06266528,5.18 7.82000104,5.07600104 7.612,4.868 C7.40399896,4.65999896 7.3,4.41733472 7.3,4.14 C7.3,3.86266528 7.40399896,3.62000104 7.612,3.412 C7.82000104,3.20399896 8.06266528,3.1 8.34,3.1 C8.61733472,3.1 8.85999896,3.20399896 9.068,3.412 C9.27600104,3.62000104 9.38,3.86266528 9.38,4.14 C9.38,4.41733472 9.27600104,4.65999896 9.068,4.868 C8.85999896,5.07600104 8.61733472,5.18 8.34,5.18 Z"
                    id="i"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      )}
    </>
  );
}

export default React.memo(InfoIcon);

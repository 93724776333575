import React, { useState } from "react";
import { Tooltip } from "@mui/material";

import styles from "./InfoIconTooltip.module.scss";
import { createModuleStyleExtractor } from "../../../utils/css";

import InfoIcon from "../../../assets/Icons/InfoIcon";

const cx = createModuleStyleExtractor(styles);

const InfoIconTooltip = ({
  content = "",
  variant = "header", // text (grey) | header (blue)
  size = "medium", //  medium | small | extra-small
  placement = "bottom-end",
  margin = "",
  className = null,
  defaultOpen = false,
  onClose = () => {},
  ...props
}) => {
  const [tooltipIsOpen, setTooltipIsOpen] = useState(defaultOpen);

  const sizePixels = size === "extra-small" ? 13 : size === "small" ? 17 : 20;
  return (
    <Tooltip
      open={tooltipIsOpen}
      onOpen={() => setTooltipIsOpen(true)}
      onClose={() => {
        setTooltipIsOpen(false);
        onClose?.();
      }}
      title={<div className="info-icon-tooltip">{content}</div>}
      placement={placement}
    >
      <a
        href="/#"
        onClick={(e) => e.preventDefault()}
        className={
          className ??
          cx(["a-info-icon-tooltip", `a-info-icon-tooltip--${variant}`])
        }
        style={{
          margin,
          height: sizePixels,
          width: sizePixels,
        }}
        {...props}
      >
        <InfoIcon variant={variant} />
      </a>
    </Tooltip>
  );
};
export default InfoIconTooltip;

/* eslint-disable no-useless-escape */
import moment from "moment";
import toastify from "./toast";
import { DOB_FORMAT } from "./constants";

export const checkValidString = (value = "") => {
  return (
    value &&
    value !== undefined &&
    value !== null &&
    value.toString().length > 0
  );
};

export const isEmpty = (value) => {
  return (
    !value ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0) ||
    (typeof value === "number" && value.toString().trim().length === 0)
  );
};

export const isNull = (value) => {
  return value === null ? true : false;
};

export const validateString = (value = "") => {
  return !value || isEmpty(value);
};

export const validateId = (value) => {
  return (
    !value || isEmpty(value.toString()) || isEmpty(value.toString().trim())
  );
};

export const validatePhoneNumber = (number = "", countryDialCode = "") => {
  // Validate number without country code
  const onlyNumber = number?.toString()?.replace(countryDialCode, "");
  return validateString(onlyNumber);
};

export const NAME_REGEX =
  /^[a-zA-ZÀ-ÿÇçŒœÆæ\s]+(([-_'][a-zA-ZÀ-ÿÇçŒœÆæ]{1,})+)?$/i;

export const validateName = (text) =>
  text && NAME_REGEX.test(text.trim()) ? false : true;

export const validateSpecialChar = (value = "") => {
  return value.trim().match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)
    ? true
    : false;
};

export const validatePassword = (value = "") => {
  return value.trim().match(/(?=.*?[A-Z])(?=.*?[a-z])/) ? false : true;
};

export const validateEmail = (value = "") => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value.trim())
    ? false
    : true;
};

export const validateArray = (value = []) => {
  return !value || isEmpty(value);
};

export const validateObject = (value = []) => {
  return !value || isEmpty(value) || isNull(value);
};

export const validateFile = (value = []) => {
  if (typeof value === "string" && value.length > 0) {
    return false;
  } else if (value instanceof File) {
    return false;
  } else {
    return true;
  }
};

export const validateUrl = (value = "") => {
  var expres = new RegExp(/^(http|https):\/\/[^ "]+$/);
  return expres.test(value) ? false : true;
};

export const validateLink = (value = "") => {
  return !isEmpty(value.trim()) &&
    /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/g.test(
      value.trim()
    )
    ? false
    : true;
};

export const validateYear = (value = "") => {
  return /^\d{4}$/.test(value) ? false : true;
};

export const getValidSearchText = (value = "") => {
  return value.replace(/[^a-zA-Z0-9\s@.~_-]/g, "");
};

export const validateAttribute = (value = "") => {
  return !(!value || isEmpty(value));
};

export const checkValidImage = (
  files,
  validTypes = ["image/png", "image/jpg", "image/jpeg"],
  maxSize = 5
) => {
  files.forEach((item) => {
    if (!validTypes.includes(item.type)) {
      toastify("Please select a valid image. (png, jpg, or jpeg)");
      //   toastify("error", "Please select a valid image. (png, jpg, or jpeg)");
      return false;
    } else if (parseInt(item.size / 1000) > maxSize * 1000) {
      toastify(`Please select an file < ${maxSize}MB.`);
      //   toastify("error", `Please select an file < ${maxSize}MB.`);
      return false;
    } else {
      return true;
    }
  });
};

export const checkCompleteProspectProfile = (user = {}, permissions = {}) => {
  if (!checkValidString(user.first_name) || !checkValidString(user.last_name))
    return false;
  if (
    permissions?.country_of_origin?.is_mendatory &&
    permissions?.country_of_origin?.is_visible &&
    !checkValidString(user.nationality)
  )
    return false;
  if (
    permissions?.degree?.is_mendatory &&
    permissions?.degree?.is_visible &&
    !checkValidString(user.degree)
  )
    return false;
  if (
    permissions?.major?.is_mendatory &&
    permissions?.major?.is_visible &&
    !checkValidString(user.major)
  )
    return false;
  if (
    permissions?.graduation_year?.is_mendatory &&
    permissions?.graduation_year?.is_visible &&
    user.graduation_year &&
    !checkValidString(`${user.graduation_year}`)
  )
    return false;
  if (
    permissions?.profile_pitcure?.is_mendatory &&
    permissions?.profile_pitcure?.is_visible &&
    !checkValidString(user.profile_image_url)
  )
    return false;

  if (
    permissions?.phone_number?.is_mendatory &&
    permissions?.phone_number?.is_visible &&
    validatePhoneNumber(user.phone_number, user.country_code)
  )
    return false;
  if (
    permissions?.dob?.is_mendatory &&
    permissions?.dob?.is_visible &&
    !(
      moment(user?.dob, "YYYY-MM-DD", true).isValid() || // This is for the initial date received from BE with the format YYYY-MM-DD
      moment(user?.dob, DOB_FORMAT, true).isValid()
    )
  )
    return false;

  return true;
};

export const checkCompleteParentProfile = (user) => {
  return (
    checkValidString(user.first_name) && checkValidString(user.last_name)
    // (checkValidString(user.profile_image_url) || user.user_profile) // User profile is optional
  );
};

export const checkCompleteAmbassadorProfile = (user) => {
  return (
    checkValidString(user.first_name) &&
    checkValidString(user.last_name) &&
    checkValidString(user.nationality) &&
    checkValidString(user.degree) &&
    checkValidString(user.major) &&
    checkValidString(user.graduation_year)
    // (checkValidString(user.profile_image_url) || user.user_profile) // User profile is optional
  );
};

export const checkInvalidSelectValue = (value = "") => {
  let invalid = false;

  if (!value || value === undefined || isNull(value || isEmpty(value)))
    invalid = true;
  else if (typeof value == "number") invalid = isEmpty(value.toString());
  else invalid = isEmpty(value);

  return invalid;
};

export const validateYoutubeURL = (url = "") => {
  const youtubeRegexExpression =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const isValid = url.match(youtubeRegexExpression);
  if (isValid) {
    return isValid[1];
  }
  return false;
};

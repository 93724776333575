import React, { useState, useEffect, memo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button } from "../../atoms/Button/Button";
import styles from "./AdmbassadorsSignInForm.module.scss";
import { Modal } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import {
  checkValidString,
  validateString,
  validateName,
} from "../../../utils/validations";
import {
  getDropDownOptions,
  updateOnboardigUserProfile,
} from "../../../api/onboarding";
import {
  setUserData as updateUserData,
  removeUserProfileImage,
} from "../../../redux/reducers/userReducer";
import {
  convertObjectToFormData,
  getGraduationYearOptions,
  getExpEnrollmentYearOptions,
  getUserProfileAccountTypeOptions,
  setMSClarityTagsForUserProfileData,
  getCapitalizedHeader,
} from "../../../utils/common";
import toastify from "../../../utils/toast";
import MuiTextbox, {
  MuiTextboxModifier,
} from "../../atoms/MuiTextbox/MuiTextbox";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import { getSelectOptions } from "../../../utils/noop";

import { updateProfileImage } from "../../../api/profile";

const cx = createModuleStyleExtractor(styles);

const AdmbassadorsSignInForm = ({ showModal = false, closeModal }) => {
  // Loader States
  const [isUpdatingProfileImage, setIsUpdatingProfileImage] = useState(false);

  let formRef = useRef();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userState);
  const { user = {}, selectedSchool } = userState;
  const { educations = [] } = user;

  const [userData, setUserData] = useState({});
  const [dropDownOptions, setDropDownOptions] = useState({
    nationalities: [],
    degrees: [],
    majors: [],
    graduation_years_alumni: [],
    graduation_years_student: [],
    account_type: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUserDataChange = (key, value) => {
    setUserData({
      ...userData,
      [key]: value,
    });
  };

  useEffect(() => {
    if (formRef.current) formRef.current.validateForm();
  }, [userData]);

  useEffect(() => {
    const getOptions = async () => {
      const school_id = selectedSchool?.id;
      const response = await getDropDownOptions({
        school_id,
        role: "ambassador",
        data_filters: {
          type: [
            { key: "nationalities" },
            { key: "degrees" },
            { key: "majors" },
          ],
        },
      });

      if (response.success && response.data) {
        setDropDownOptions({
          degrees: getSelectOptions(response.data.degrees),
          majors: getSelectOptions(response.data.majors),
          graduation_years_alumni: getGraduationYearOptions(),
          graduation_years_student: getExpEnrollmentYearOptions(),
          nationalities: getSelectOptions(response.data.nationalities),
          account_type: getUserProfileAccountTypeOptions(),
        });
      } else {
        toastify(response.message);
      }
    };

    getOptions();

    setUserData({
      first_name: checkValidString(user.first_name) ? user.first_name : "",
      last_name: checkValidString(user.last_name) ? user.last_name : "",
      nationality: checkValidString(user.nationality) ? user.nationality : "",
      degree: checkValidString(educations?.[0]?.degree)
        ? educations[0].degree
        : "",
      major: checkValidString(educations?.[0]?.major)
        ? educations[0].major
        : "",
      graduation_year: checkValidString(educations?.[0]?.graduation_year)
        ? educations[0].graduation_year
        : "",
      user_profile: user.profile_image_url ?? "",
      account_type: user.account_type ?? "",
      intro: user.intro ?? "",
    });
    setMSClarityTagsForUserProfileData(user);
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    let apiUserData = { ...userData };
    if (userData.account_type === "staff") {
      // Filter out degree and major
      delete apiUserData["degree"];
      delete apiUserData["major"];
    }
    if (typeof apiUserData.user_profile === "string") {
      delete apiUserData.user_profile;
    }
    setIsSubmitting(true);
    const response = await updateOnboardigUserProfile(
      user.id,
      convertObjectToFormData("user", apiUserData)
    );

    if (response.success && response.data) {
      const { users = {} } = response.data;
      dispatch(updateUserData(users));
      toastify(response.message);
      closeModal(users);
    } else {
      setIsSubmitting(false);
      toastify(response.message);
    }
  };

  const handleProfileImageRemove = async (status) => {
    if (status) {
      setIsUpdatingProfileImage(true);

      let formData = new FormData();
      formData.append("remove_user_profile", true);

      const response = await updateProfileImage(formData);
      if (response?.success) {
        handleUserDataChange("user_profile", ""); // Update form state
        setIsUpdatingProfileImage(false); // Disable loader
        toastify(response.message ?? "Profile image removed"); // Show toast message
        dispatch(removeUserProfileImage()); // Update user redux state
      } else {
        setIsUpdatingProfileImage(false);
        toastify("Oops! something went wrong. Please try again");
      }
    }
  };

  return (
    <div className={cx("m-ambassador-sign-in-form-container")}>
      <Modal show={showModal} hideClose centerAlign={false}>
        <Formik
          innerRef={formRef}
          initialValues={{
            account_type: "",
            first_name: "",
            last_name: "",
            nationality: "",
            degree: "",
            major: "",
            graduation_year: "",
            user_profile: "",
            intro: "",
          }}
          validate={() => {
            let errors = {};
            if (validateString(userData.first_name)) {
              errors.first_name = "First name is required";
            } else if (validateName(userData.first_name)) {
              errors.first_name = "First name is not valid";
            }
            if (validateString(userData.last_name)) {
              errors.last_name = "Last name is required";
            } else if (validateName(userData.last_name)) {
              errors.last_name = "Last name is not valid";
            }
            if (validateString(userData.nationality)) {
              errors.nationality = "Nationality is required";
            }
            if (
              userData.account_type !== "staff" &&
              validateString(userData.degree)
            ) {
              errors.degree = "Degree is required";
            }
            if (
              userData.account_type !== "staff" &&
              validateString(userData.major)
            ) {
              errors.major = "Major is required";
            }
            if (
              userData.account_type !== "staff" &&
              validateString(userData.graduation_year)
            ) {
              errors.graduation_year = "Graduation year is required";
            }
            if (userData.intro === "") {
              errors.intro = "Introduction is required";
            }
            return errors;
          }}
          onSubmit={() => {
            // same shape as initial values
            handleSubmit();
          }}
        >
          {({ errors, touched, isValidating, handleBlur }) => (
            <Form>
              <ModalBody>
                <h2>Finalize your profile to get started.</h2>

                <div
                  className={cx(
                    "m-prospect-sign-in-form-container__formfields"
                  )}
                >
                  <Field name="user_profile">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields__user-profile"
                        )}
                      >
                        <label>Upload a profile picture (optional)</label>
                        <FileUpload
                          {...field}
                          name={"user_profile"}
                          userName={user.name}
                          maxFileSize={5}
                          defaultBackgroundImage={userData.user_profile}
                          onSelectSuccess={(file) =>
                            handleUserDataChange("user_profile", file)
                          }
                          onRemoveSuccess={(status) =>
                            handleProfileImageRemove(status)
                          }
                          loader={isUpdatingProfileImage}
                          cropperAlign="start"
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="account_type">
                    {({ field, meta, form: { setFieldTouched } }) => (
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields--group"
                        )}
                      >
                        <MuiSelect
                          {...field}
                          label={"Account type*"}
                          placeholder={"Account type"}
                          name={"account_type"}
                          value={
                            dropDownOptions.account_type
                              ? dropDownOptions.account_type.find(
                                  (option) =>
                                    option.value === userData.account_type
                                )
                              : ""
                          }
                          options={dropDownOptions.account_type}
                          onChange={(e) =>
                            handleUserDataChange("account_type", e.value)
                          }
                          onBlur={(e) => setFieldTouched("account_type", true)}
                          error={meta.touched && meta.error}
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="first_name">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields--group"
                        )}
                      >
                        <MuiTextbox
                          {...field}
                          value={userData.first_name}
                          onChange={(e) =>
                            handleUserDataChange(
                              "first_name",
                              getCapitalizedHeader(e.target.value)
                            )
                          }
                          name={"first_name"}
                          label="Your first name*"
                          placeholder={"Enter first name"}
                          error={meta.touched && meta.error}
                          modifier={MuiTextboxModifier.CENTER_ALIGN}
                        />
                      </div>
                    )}
                  </Field>

                  <Field name="last_name">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields--group"
                        )}
                      >
                        <MuiTextbox
                          {...field}
                          value={userData.last_name}
                          onChange={(e) =>
                            handleUserDataChange("last_name", e.target.value)
                          }
                          name={"last_name"}
                          label="Your last name*"
                          placeholder={"Enter last name"}
                          error={meta.touched && meta.error}
                          modifier={MuiTextboxModifier.CENTER_ALIGN}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="nationality">
                    {({ field, meta, form: { setFieldTouched } }) => (
                      <div
                        className={cx(
                          "m-prospect-sign-in-form-container__formfields--group"
                        )}
                      >
                        <MuiSelect
                          {...field}
                          label={"Your nationality*"}
                          placeholder={"Select a country"}
                          name={"nationality"}
                          value={
                            dropDownOptions.nationalities
                              ? dropDownOptions.nationalities.find(
                                  (option) =>
                                    option.value === userData.nationality
                                )
                              : ""
                          }
                          options={dropDownOptions.nationalities}
                          onChange={(e) =>
                            handleUserDataChange("nationality", e.value)
                          }
                          onBlur={(e) => setFieldTouched("nationality", true)}
                          error={meta.touched && meta.error}
                        />
                      </div>
                    )}
                  </Field>

                  {userData.account_type !== "staff" && (
                    <>
                      <Field name="degree">
                        {({ field, meta, form: { setFieldTouched } }) => (
                          <div
                            className={cx(
                              "m-prospect-sign-in-form-container__formfields--group"
                            )}
                          >
                            <MuiSelect
                              {...field}
                              label={"Your degree*"}
                              placeholder={"Select a degree"}
                              name={"degree"}
                              value={
                                dropDownOptions.degrees
                                  ? dropDownOptions.degrees.find(
                                      (option) =>
                                        option.value === userData.degree
                                    )
                                  : ""
                              }
                              options={dropDownOptions.degrees}
                              onChange={(e) =>
                                handleUserDataChange("degree", e.value)
                              }
                              onBlur={(e) => setFieldTouched("degree", true)}
                              error={meta.touched && meta.error}
                            />
                          </div>
                        )}
                      </Field>
                      <Field name="major">
                        {({ field, meta, form: { setFieldTouched } }) => (
                          <div
                            className={cx(
                              "m-prospect-sign-in-form-container__formfields--group"
                            )}
                          >
                            <MuiSelect
                              {...field}
                              label="Your major*"
                              placeholder={"Enter major"}
                              name={"major"}
                              value={
                                dropDownOptions.majors === null
                                  ? ""
                                  : dropDownOptions.majors !== null &&
                                    dropDownOptions.majors.includes(
                                      (option) =>
                                        option.value === userData.major
                                    )
                                  ? dropDownOptions.majors.find(
                                      (option) =>
                                        option.value === userData.major
                                    )
                                  : {
                                      label: userData.major,
                                      value: userData.major,
                                    }
                              }
                              options={dropDownOptions.majors}
                              onChange={(e) =>
                                handleUserDataChange("major", e.value)
                              }
                              onBlur={(e) => setFieldTouched("major", true)}
                              error={meta.touched && meta.error}
                              addText="Add major"
                              editable={true}
                            />
                          </div>
                        )}
                      </Field>
                      {userData.account_type === "alumni" ? (
                        <Field name="graduation_year">
                          {({ field, meta, form: { setFieldTouched } }) => (
                            <div
                              className={cx(
                                "m-prospect-sign-in-form-container__formfields--group"
                              )}
                            >
                              <MuiSelect
                                {...field}
                                name={"graduation_year"}
                                label={"Your graduation year*"}
                                placeholder={"Select a year"}
                                value={
                                  dropDownOptions.graduation_years_alumni
                                    ? dropDownOptions.graduation_years_alumni.find(
                                        (option) =>
                                          option.value ===
                                          userData.graduation_year
                                      )
                                    : ""
                                }
                                options={
                                  dropDownOptions.graduation_years_alumni
                                }
                                onChange={(e) =>
                                  handleUserDataChange(
                                    "graduation_year",
                                    e.value
                                  )
                                }
                                onBlur={(e) =>
                                  setFieldTouched("graduation_year", true)
                                }
                                error={meta.touched && meta.error}
                              />
                            </div>
                          )}
                        </Field>
                      ) : (
                        <Field name="graduation_year">
                          {({ field, meta, form: { setFieldTouched } }) => (
                            <div
                              className={cx(
                                "m-prospect-sign-in-form-container__formfields--group"
                              )}
                            >
                              <MuiSelect
                                {...field}
                                name={"graduation_year"}
                                label={"Your graduation year*"}
                                placeholder={"Select a year"}
                                value={
                                  dropDownOptions.graduation_years_student
                                    ? dropDownOptions.graduation_years_student.find(
                                        (option) =>
                                          option.value ===
                                          userData.graduation_year
                                      )
                                    : ""
                                }
                                options={
                                  dropDownOptions.graduation_years_student
                                }
                                onChange={(e) =>
                                  handleUserDataChange(
                                    "graduation_year",
                                    e.value
                                  )
                                }
                                onBlur={(e) =>
                                  setFieldTouched("graduation_year", true)
                                }
                                error={meta.touched && meta.error}
                              />
                            </div>
                          )}
                        </Field>
                      )}
                    </>
                  )}
                  <Field name="intro">
                    {({ field, meta }) => (
                      <div
                        className={cx([
                          "m-prospect-sign-in-form-container__formfields--group",
                          "m-prospect-sign-in-form-container__formfields--textarea",
                        ])}
                      >
                        <MuiTextbox
                          {...field}
                          isMultiline
                          rows={2}
                          value={userData.intro}
                          onChange={(e) =>
                            handleUserDataChange("intro", e.target.value)
                          }
                          name={"intro"}
                          label="Your introduction*"
                          placeholder="Give a short intro about who you are. This will be visible to students in the network portal."
                          error={meta.touched && meta.error}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </ModalBody>
              <ModalFooter
                className={cx("m-prospect-sign-in-form-container__footer")}
              >
                <Button
                  type="submit"
                  isSubmitting={isSubmitting}
                  disabled={
                    Object.keys(formRef?.current?.errors || {})?.length > 0
                  }
                >
                  Enter portal
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};
export default memo(AdmbassadorsSignInForm);

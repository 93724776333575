import React, {
  useState,
  useEffect,
  useLayoutEffect,
  memo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import { useViewport } from "../../../hooks/useViewPort";
import { detectDispalyMode, swiperConfig } from "../../../utils/common";
import { createModuleStyleExtractor } from "../../../utils/css";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import { Col } from "../../helpers/Grid/Col";
import { Row } from "../../helpers/Grid/Row";
import ChatPreview from "../../molecules/ChatPreview/ChatPreview";
import InitChat from "../../molecules/InitChat/InitChat";
import { Modal } from "../../atoms/Modal/Modal";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { readChatNotification } from "../../../api/notification";
import { readChatSpecificNotification } from "../../../redux/reducers/notificationReducer";
import { setChatInfoStatus } from "../../../redux/reducers/preservedReducer";
import styles from "./Chat.module.scss";
import {
  bulkUpdate,
  flushFreshChats,
  setChatsList,
  setCurrentConversationId,
  setPreviousActiveConversation,
} from "../../../redux/reducers/chat/reducers";
import { ROUTES } from "../../../routes";
import InfoIconTooltip from "../../atoms/InfoIconTooltip/InfoIconTooltip";

const cx = createModuleStyleExtractor(styles);

const Chat = () => {
  const mounted = useRef(true);
  const handle = useFullScreenHandle();
  const { width } = useViewport();
  const breakpoint = 993;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const persisteState = useRef();

  const [isMobile, setIsMobile] = useState(width < breakpoint);
  const [pathURLConversationId, setPathURLConversationId] = useState(null);
  const [isViewed, setIsViewed] = useState(() =>
    Boolean(localStorage.getItem("isViewed"))
  );

  const { chatInfoStatus = false } = useSelector(
    (state) => state.preservedState
  );
  const chatStore = useSelector((store) => store.chatStore);
  const {
    activeConversation,
    previousActiveConversation,
    isChatPreview,
    isProfileView,
    isConversationView,
    isChatsLoaded,
    isChatsLoading,
    chats,
  } = chatStore || {};
  const { chatSpecificNotificationCount } = useSelector(
    (state) => state.notificationState
  );

  useEffect(() => {
    if (chats?.length > 0 && activeConversation === -1) {
      dispatch(setChatsList({ chats: chats }));
      if (chats?.[0]?.conversation_id) {
        dispatch(setCurrentConversationId(chats[0].conversation_id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation, chats]);

  useEffect(() => {
    persisteState.current = chatStore;
  }, [chatStore]);

  useEffect(() => {
    mounted.current = !mounted.current;
    //////////// Clean away chats without handshake
    return () => {
      if (mounted.current) {
        const { freshChat, chats } = persisteState.current;
        if (freshChat?.length > 0) {
          if (chats?.length > 0) {
            const _chats = chats.filter(
              (chat) => !freshChat.includes(chat.conversation_id)
            );
            dispatch(setChatsList({ chats: _chats }));
            _chats?.length > 0 &&
              dispatch(setCurrentConversationId(_chats[0].conversation_id));
          }
          dispatch(flushFreshChats());
        }
      }
      dispatch(setPreviousActiveConversation(-1));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Read chat notification upon conversation change
  useEffect(() => {
    (async () => {
      // Check if previous conversation has notification, if yes then call api and update state
      if (
        chatSpecificNotificationCount[previousActiveConversation] &&
        chatSpecificNotificationCount[previousActiveConversation] > 0
      ) {
        let formData = new FormData();
        formData.append("conversation_id", previousActiveConversation);
        const response = await readChatNotification(formData);
        if (response?.success) {
          const { chat_notifcations_count, unread_notification_count } =
            response.data ?? {};
          dispatch(
            readChatSpecificNotification({
              conversation_id: previousActiveConversation,
              chat_notifcations_count,
              unread_notification_count,
            })
          );
        } else {
          console.error(response);
        }
      }
      // Check if current conversation has notification, if yes then call api and update state
      if (
        chatSpecificNotificationCount[activeConversation] &&
        chatSpecificNotificationCount[activeConversation] > 0
      ) {
        let formData = new FormData();
        formData.append("conversation_id", activeConversation);
        const response = await readChatNotification(formData);
        if (response?.success) {
          const { chat_notifcations_count, unread_notification_count } =
            response.data ?? {};
          dispatch(
            readChatSpecificNotification({
              conversation_id: activeConversation,
              chat_notifcations_count,
              unread_notification_count,
            })
          );
        } else {
          console.error(response);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeConversation]);

  const handleNavigation = (url) => {
    navigate(url);
  };

  const handleSkip = () => {
    localStorage.setItem("isViewed", true);
    setIsViewed(true);
  };

  useLayoutEffect(() => {
    setIsMobile(width < breakpoint);
  }, [width]);

  const handleSwipeOperation = (state) => {
    dispatch(bulkUpdate({ ...state }));
  };

  const config = swiperConfig();

  const handleSwipeLeft = () => {
    if (isChatPreview) {
      handleSwipeOperation({ isConversationView: true, isChatPreview: false });
      return;
    }

    handleSwipeOperation({ isConversationView: false, isProfileView: true });
  };

  const handleSwipeRight = () => {
    if (isChatPreview) return;
    if (isConversationView) {
      handleSwipeOperation({
        isConversationView: false,
        isProfileView: false,
        isChatPreview: true,
      });
      return;
    }

    if (isProfileView) {
      handleSwipeOperation({
        isConversationView: true,
        isProfileView: false,
        isChatPreview: false,
      });
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipeLeft(),
    onSwipedRight: () => handleSwipeRight(),
    ...config,
  });

  useEffect(() => {
    // Upon DOM load, check URL search params and extract conversation ID into component local state
    const pathUrlQuery = window.location.search;
    if (pathUrlQuery?.includes("conversation_id")) {
      const searchParamObj = new URLSearchParams(pathUrlQuery);
      const pathConversationId = searchParamObj.get("conversation_id");
      if (pathConversationId)
        setPathURLConversationId(parseInt(pathConversationId));
    }
  }, []);

  // Set path conversation ID in redux state
  useEffect(() => {
    if (
      pathURLConversationId &&
      isChatsLoaded &&
      !isChatsLoading &&
      chats?.findIndex(
        (element) => element?.conversation_id === pathURLConversationId
      ) !== -1 &&
      activeConversation !== pathURLConversationId
    ) {
      dispatch(setCurrentConversationId(pathURLConversationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathURLConversationId, isChatsLoaded, isChatsLoading]);

  return (
    <div id={"messenger-ref"}>
      <div className={cx("o-chat-container")} {...handlers}>
        {!isViewed && (
          <div className={cx("o-chat-container__swipe-indication")}>
            <Modal>
              <ModalBody>
                <img
                  src="/images/swipe-left-right.jpg"
                  width={"100%"}
                  alt="swipe-left"
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  onClick={() => handleSkip()}
                  modifier={ButtonModifier.INVITE_SKIP_BUTTON}
                >
                  Skip
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        )}
        <FullScreen handle={handle}>
          <div className={cx("o-chat-container__header")}>
            <div className={cx("left-section")}>
              <div className={cx("o-chat-container__header-heading")}>
                <span className="info-title">
                  Your messages
                  <InfoIconTooltip
                    margin={"3px 0px 0px 5px"}
                    placement="bottom"
                    defaultOpen={chatInfoStatus !== true}
                    onClose={() => dispatch(setChatInfoStatus(true))}
                    content={
                      <>
                        Kindly refrain from sharing personal or sensitive
                        information with others.
                      </>
                    }
                  />
                </span>
              </div>
              <div className={cx("o-chat-container__header-button")}>
                <Button
                  modifier={ButtonModifier.DISCOVER_NETWORK_BUTTON}
                  onClick={() => handleNavigation(ROUTES.CHAT.AMBASSADORS)}
                >
                  Chat
                </Button>
              </div>
            </div>
          </div>

          <Row className={"o-chat-grid-container"}>
            <Col
              xs={12}
              lg={3}
              sm={4}
              md={4}
              styles={{ display: detectDispalyMode(isMobile, isChatPreview) }}
            >
              <ChatPreview isMobile={isMobile} />
            </Col>
            <Col xs={12} lg={9} sm={8} md={8}>
              <InitChat isMobile={isMobile} />
            </Col>
          </Row>
        </FullScreen>
      </div>
    </div>
  );
};

export default memo(Chat);

import React, { useState, useEffect, memo, useRef } from "react";
import { Formik, Form, Field } from "formik";

import { createModuleStyleExtractor } from "../../../utils/css";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import styles from "./ParentProspectInviteForm.module.scss";
import { Modal } from "../../atoms/Modal/Modal";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { ModalFooter } from "../../atoms/Modal/ModalFooter/ModalFooter";
import {
  validateString,
  validateEmail,
  validateName,
} from "../../../utils/validations";
import { inviteProspectParent } from "../../../api/onboarding";
import toastify from "../../../utils/toast";
import MuiTextbox, {
  MuiTextboxModifier,
} from "../../atoms/MuiTextbox/MuiTextbox";
import { getCapitalizedHeader } from "../../../utils/common";

const cx = createModuleStyleExtractor(styles);

const ParentProspectInviteForm = ({
  showModal = false,
  closeModal = () => {},
  role = "parent",
  onUserInvited = () => {},
}) => {
  let formRef = useRef();

  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({ invite_by_type: role });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleUserDataChange = (key, value) => {
    setUserData({
      ...userData,
      [key]: value,
    });
  };

  useEffect(() => {
    if (formRef.current) formRef.current.validateForm();
  }, [userData]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const response = await inviteProspectParent(userData);

    if (response.success && response.data) {
      toastify(response.message);
      onUserInvited();
      closeModal();
    } else {
      setIsSubmitting(false);
      toastify(response.message);
    }
  };

  return (
    <div className={cx("m-parent-prospect-invite-form")}>
      <Modal show={showModal} centerAlign={false} onClose={closeModal}>
        {step === 1 ? (
          <>
            <ModalBody>
              <h2>
                Invite a{" "}
                {role === "parent"
                  ? "parent or guardian"
                  : "prospective student"}{" "}
                to the portal
              </h2>

              <span className={cx("m-parent-prospect-invite-form__subtitle")}>
                You can grant access to this admissions portal with a parent or
                guardian. They will be able to create an account and access the
                same resources. <br />
                <br /> You can also do this in the future in the Profile
                section.
              </span>
            </ModalBody>
            <ModalFooter
              className={cx("m-parent-prospect-invite-form__footer")}
            >
              <Button onClick={() => setStep(2)}>Send invite</Button>
              <Button
                modifier={ButtonModifier.INVITE_SKIP_BUTTON}
                type="button"
                onClick={closeModal}
              >
                I’ll do it later
              </Button>
            </ModalFooter>
          </>
        ) : (
          <Formik
            key={"invite-form"}
            innerRef={formRef}
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
            }}
            validate={() => {
              let errors = {};

              if (validateString(userData.first_name)) {
                errors.first_name = "First name is required";
              } else if (validateName(userData.first_name)) {
                errors.first_name = "First name is not valid";
              }
              if (validateString(userData.last_name)) {
                errors.last_name = "Last name is required";
              } else if (validateName(userData.last_name)) {
                errors.last_name = "Last name is not valid";
              }
              if (validateString(userData.email)) {
                errors.email = "Email is required";
              } else if (validateEmail(userData.email)) {
                errors.email = "Email is not valid";
              }

              return errors;
            }}
            onSubmit={(values) => {
              handleSubmit();
            }}
          >
            <Form>
              <ModalBody>
                <h2>
                  Invite a{" "}
                  {role === "parent"
                    ? "parent or guardian"
                    : "prospective student"}{" "}
                  to the portal
                </h2>

                <span className={cx("m-parent-prospect-invite-form__subtitle")}>
                  You can do this in the future in the Profile section.
                </span>
                <div
                  className={cx("m-parent-prospect-invite-form__formfields")}
                >
                  <Field name="first_name">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-parent-prospect-invite-form__formfields--group"
                        )}
                      >
                        <MuiTextbox
                          {...field}
                          value={userData.first_name}
                          onChange={(e) =>
                            handleUserDataChange(
                              "first_name",
                              getCapitalizedHeader(e.target.value)
                            )
                          }
                          name={"first_name"}
                          label="First name*"
                          placeholder={"Enter first name"}
                          error={meta.touched && meta.error}
                          modifier={MuiTextboxModifier.CENTER_ALIGN}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="last_name">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-parent-prospect-invite-form__formfields--group"
                        )}
                      >
                        <MuiTextbox
                          {...field}
                          value={userData.last_name}
                          onChange={(e) =>
                            handleUserDataChange("last_name", e.target.value)
                          }
                          name={"last_name"}
                          label="Last name*"
                          placeholder={"Enter last name"}
                          error={meta.touched && meta.error}
                          modifier={MuiTextboxModifier.CENTER_ALIGN}
                        />
                      </div>
                    )}
                  </Field>
                  <Field name="email">
                    {({ field, meta }) => (
                      <div
                        className={cx(
                          "m-parent-prospect-invite-form__formfields--group"
                        )}
                      >
                        <MuiTextbox
                          {...field}
                          value={userData.email}
                          onChange={(e) =>
                            handleUserDataChange("email", e.target.value)
                          }
                          name={"email"}
                          label="Email address*"
                          placeholder={"Enter email address"}
                          error={meta.touched && meta.error}
                          modifier={MuiTextboxModifier.CENTER_ALIGN}
                        />
                      </div>
                    )}
                  </Field>
                </div>
              </ModalBody>
              <ModalFooter
                className={cx("m-parent-prospect-invite-form__footer")}
              >
                <Button
                  type="button"
                  isSubmitting={isSubmitting}
                  onClick={() => {
                    if (formRef.current) formRef.current.submitForm();
                  }}
                >
                  Send invite
                </Button>
                <Button
                  modifier={ButtonModifier.INVITE_SKIP_BUTTON}
                  type="button"
                  onClick={closeModal}
                >
                  I’ll do it later
                </Button>
              </ModalFooter>
            </Form>
          </Formik>
        )}
      </Modal>
    </div>
  );
};
export default memo(ParentProspectInviteForm);

import React, { memo, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import { ShimmerPostItem } from "react-shimmer-effects";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { createModuleStyleExtractor } from "../../../utils/css";
import styles from "./Profile.module.scss";
import { Modal } from "../../atoms/Modal/Modal";
import { ModelHeader } from "../../atoms/Modal/ModelHeader/ModelHeader";
import { ModalBody } from "../../atoms/Modal/ModalBody/ModalBody";
import { Button, ButtonModifier } from "../../atoms/Button/Button";
import toastify from "../../../utils/toast";
import {
  updateUser,
  updateProfileImage,
  closeAccount,
  getUserProfileDetails,
} from "../../../api/profile";
import {
  resetLoginData,
  setUserData,
  removeUserProfileImage,
} from "../../../redux/reducers/userReducer";
import { resetNotificationData } from "../../../redux/reducers/notificationReducer";
import { resetChatState } from "../../../redux/reducers/chat/reducers";
import { resetInitialState } from "../../../redux/reducers/webSocketReducer";
import { resetCalculatorData } from "../../../redux/reducers/calculatorReducer";
import Card from "../../atoms/Card/Card";
import MuiSelect from "../../atoms/MuiSelect/MuiSelect";
import MuiTextbox from "../../atoms/MuiTextbox/MuiTextbox";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import ToggleSwitch from "../../atoms/ToggleSwitch/ToggleSwitch";
import PhoneNumberSelect from "../../atoms/PhoneNumberSelect/PhoneNumberSelect";
import {
  getCapitalizedHeader,
  getExpEnrollmentYearOptions,
  getGraduationYearOptions,
  getUserProfileAccountTypeOptions,
  wipeOutUserData,
} from "../../../utils/common";
import EditPasswordModal from "./EditPasswordModal/EditPasswordModal";
import EditEmailModal from "./EditEmailModal/EditEmailModal";
import PreviewProfileModal from "./PreviewProfileModal/PreviewProfileModal";
import { getDropDownOptions } from "../../../api/onboarding";
import {
  validateString,
  validateFile,
  validatePhoneNumber,
  validateName,
} from "../../../utils/validations";
import { Mixpanel } from "../../../MixPanel/mixpanel";
import { ViewIcon } from "../../atoms/SvgComponents/svgComponents";
import ParentProspectInviteForm from "../../molecules/ParentProspectInviteForm/ParentProspectInviteForm";
import SignOutModal from "./SignOutModal/SignOutModal";
import { ROUTES } from "../../../routes";
import CustomDatePicker from "../../atoms/DatePicker/DatePicker";
import { DOB_FORMAT } from "../../../utils/constants";

const cx = createModuleStyleExtractor(styles);

const Profile = () => {
  const {
    isProspect,
    isParent,
    isStudentAmbassador,
    isAlumniAmbassador,
    isAdmin,
    user: globalUser,
    permissions,
    selectedSchool,
  } = useSelector((state) => state.userState);
  const dispatch = useDispatch();
  let formRef = useRef();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [nationalityOptions, setNationalityOptions] = useState(null);
  const [degreeOptions, setDegreeOptions] = useState(null);
  const [majorOptions, setMajorOptions] = useState(null);

  // Modal States
  const [showEditEmailModal, setShowEditEmailModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showPreviewProffile, setShowPreviewProfile] = useState(false);
  const [showSignoutModal, setShowSignoutModal] = useState(false);
  const [showCloseAccountModal, setShowCloseAccountModal] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);

  // Loader States
  const [isUpdatingProfileImage, setIsUpdatingProfileImage] = useState(false);

  // User profile dropdown options data
  const accountTypeOptions = getUserProfileAccountTypeOptions();
  const expectedEnrollmentYearOptions = getExpEnrollmentYearOptions();
  const graduationYearOptions = getGraduationYearOptions();

  const INVITEE_LIMIT = 2;

  // Selected School Profile Field Settings
  const onboardingSettings = permissions?.custom_onboarding_field;
  const profilePictureSetting = onboardingSettings?.profile_pitcure;
  const nationalitySetting = onboardingSettings?.country_of_origin;
  const phoneNoSetting = onboardingSettings?.phone_number;
  const degreeSetting = onboardingSettings?.degree;
  const majorSetting = onboardingSettings?.major;
  const enrollmentYearSetting = onboardingSettings?.graduation_year;
  const dobSetting = onboardingSettings?.dob;

  const fetchUserProfileData = async () => {
    // Call user details API and update local state

    try {
      setLoading(true);
      const response = await getUserProfileDetails();
      if (response.success) {
        const {
          user = {},
          educations = [],
          invitee_list = [],
          accessibility_help,
        } = response.data ?? {};

        setUser({
          ...user,
          ...(educations[0] ? educations[0] : {}),
          invitee_list: invitee_list || [],
          accessibility_help,
          dob: `${moment(user.dob).format(DOB_FORMAT)}`,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // On component load
  useEffect(() => {
    Mixpanel.track(`Profile visited`);

    fetchUserProfileData();

    (async () => {
      try {
        const school_id = selectedSchool?.id;
        const role = isProspect
          ? "prospect"
          : isParent
          ? "parent"
          : isAlumniAmbassador || isStudentAmbassador
          ? "ambassador"
          : isAdmin
          ? "admin"
          : "other";
        const response = await getDropDownOptions({
          school_id,
          role,
          data_filters: {
            type: [
              { key: "nationalities" },
              { key: "degrees" },
              { key: "majors" },
            ],
          },
        });
        if (response.success) {
          const { nationalities, degrees, majors } = response.data ?? {};

          // Reformat data to be used for dropdown component and store it in local state
          let _nationalities = [];
          nationalities.forEach((element) => {
            _nationalities.push({ label: element, value: element });
          });
          // Add extra option of do no disclose
          _nationalities.push({ label: "Don’t reveal", value: "Don’t reveal" });
          setNationalityOptions(_nationalities);

          let _degrees = [];
          degrees.forEach((element) => {
            _degrees.push({ label: element, value: element });
          });
          setDegreeOptions(_degrees);

          let _majors = [];
          majors.forEach((element) => {
            _majors.push({ label: element, value: element });
          });
          setMajorOptions(_majors);
        }
      } catch (error) {
        console.error(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Track url search params
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isReload = urlParams.get("reload");
    // If reload param found, recall API to fetch user profile data
    if (isReload === "true") {
      fetchUserProfileData();
      navigate(ROUTES.PROFILE); // Clear search param
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  const handleOnSignout = () => {
    setShowSignoutModal(false);
    setShowCloseAccountModal(false);
    dispatch(resetLoginData());
    dispatch(resetNotificationData());
    dispatch(resetChatState());
    dispatch(resetInitialState()); // Web socket redux state reset
    dispatch(resetCalculatorData());
    wipeOutUserData();
  };

  const handleCloseAccount = async () => {
    try {
      const response = await closeAccount();
      if (response?.success) {
        toastify(response.message ?? "User Account Successfully Closed");
        handleOnSignout();
      }
    } catch (error) {
      toastify("Oops! something went wrong. Please try again");
      console.error(error);
    }
  };

  const handleUserInvite = () => {
    fetchUserProfileData();
  };

  const handleSave = async (values) => {
    // Prepare API payload
    let formData = new FormData();
    if (isStudentAmbassador || isAlumniAmbassador) {
      formData.append("[user][account_type]", values.accountType.value);
    }
    if (typeof values.profileImage !== "string") {
      formData.append("[user][user_profile]", values.profileImage);
    }
    formData.append("[user][first_name]", values.firstName);
    formData.append("[user][last_name]", values.lastName);
    if (dobSetting?.is_visible && values?.dob && isProspect) {
      formData.append("[user][dob]", values.dob);
    }
    if (
      (isStudentAmbassador || isAlumniAmbassador || isProspect) &&
      values?.nationality?.value
    ) {
      formData.append("[user][nationality]", values.nationality.value);
    }
    if (
      isProspect &&
      !validatePhoneNumber(values.phone_number, values.country_code)
    ) {
      formData.append(
        "[user][phone_number]",
        values.phone_number.replace(values.country_code, "")
      );
      formData.append("[user][country_code]", values.country_code);
    }

    if ((!isProspect || !isParent) && values?.intro) {
      formData.append("[user][intro]", values.intro);
    }
    if (
      (isStudentAmbassador || isAlumniAmbassador || isProspect) &&
      values?.degree?.value
    ) {
      formData.append("[user][degree]", values.degree.value);
    }
    if (
      (isStudentAmbassador || isAlumniAmbassador || isProspect) &&
      values?.major?.value
    ) {
      formData.append("[user][major]", values.major.value);
    }
    if (
      (isStudentAmbassador || isAlumniAmbassador || isProspect) &&
      values?.graduationYear?.value
    ) {
      formData.append("[user][graduation_year]", values.graduationYear.value);
    }
    formData.append("[user][accessibility_help]", values.accessibilityMode);

    // Call API to save profile
    try {
      const response = await updateUser(globalUser.id, formData);
      if (response.success) {
        toastify(response?.message);
        // Update local user state
        dispatch(setUserData(response?.data?.users));
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Validation function for profile fields
  const validate = (values) => {
    const errors = {};

    // Profile Picture
    if (
      profilePictureSetting?.is_mendatory &&
      profilePictureSetting?.is_visible &&
      isProspect &&
      validateFile(values.profileImage)
    ) {
      errors.profileImage = "Profile picture is required";
    }

    // First Name
    if (validateString(values.firstName)) {
      errors.firstName = "First name is required";
    } else if (validateName(values.firstName)) {
      errors.firstName = "First name is not valid";
    }

    // Last Name
    if (validateString(values.lastName)) {
      errors.lastName = "Last name is required";
    } else if (validateName(values.lastName)) {
      errors.lastName = "Last name is not valid";
    }

    // Birth Date
    if (
      isProspect &&
      dobSetting?.is_visible &&
      dobSetting?.is_mendatory &&
      !moment(values.dob, DOB_FORMAT, true).isValid()
    ) {
      errors.dob = "Birth date is required";
    }

    // Nationality
    if (
      values.nationality === null &&
      (isAlumniAmbassador ||
        isStudentAmbassador ||
        (isProspect &&
          nationalitySetting?.is_mendatory &&
          nationalitySetting?.is_visible))
    ) {
      errors.nationality = "Please select a nationality";
    }

    // Phone number
    if (
      isProspect &&
      phoneNoSetting?.is_mendatory &&
      phoneNoSetting?.is_visible &&
      validatePhoneNumber(values.phone_number, values.country_code)
    ) {
      errors.phone_number = "Phone number is required";
    }

    // Introduction
    if (values.intro === "" && !isProspect && !isParent) {
      errors.intro = "Please enter an introduction";
    }

    // Degree
    if (
      values.degree === null &&
      (isAlumniAmbassador ||
        isStudentAmbassador ||
        (isProspect &&
          degreeSetting?.is_mendatory &&
          degreeSetting?.is_visible))
    ) {
      errors.degree = "Please select a degree";
    }

    // Major
    if (
      values.major === null &&
      (isAlumniAmbassador ||
        isStudentAmbassador ||
        (isProspect && majorSetting?.is_mendatory && majorSetting?.is_visible))
    ) {
      errors.major = "Please select a major";
    }

    // Graduation Year
    if (
      !values.graduationYear &&
      (isAlumniAmbassador ||
        isStudentAmbassador ||
        (isProspect &&
          expectedEnrollmentYearOptions?.is_mendatory &&
          expectedEnrollmentYearOptions?.is_visible))
    ) {
      errors.graduationYear = "Please enter year";
    }

    return errors;
  };

  const handleProfileImageRemove = async (status) => {
    if (status) {
      setIsUpdatingProfileImage(true);

      let formData = new FormData();
      formData.append("remove_user_profile", true);

      const response = await updateProfileImage(formData);
      if (response?.success) {
        // Check if form formRef.current has a method of set field value then execute it
        // Used this check to avoid bugsnag error
        typeof formRef?.current?.setFieldValue === "function" &&
          formRef?.current?.setFieldValue("profileImage", "");

        setIsUpdatingProfileImage(false);
        toastify(response.message ?? "Profile image removed");
        dispatch(removeUserProfileImage()); // Update user redux state
      } else {
        setIsUpdatingProfileImage(false);
        toastify("Oops! something went wrong. Please try again");
      }
    }
  };

  return (
    <div className={cx("o-profile")}>
      {loading ? (
        <div className={cx("o-profile__loader")}>
          {Array.from(Array(3).keys()).map((_, index) => (
            <div className={cx("o-profile__loader__row")} key={index}>
              <ShimmerPostItem
                card
                title
                cta
                imageType="circular"
                imageWidth={70}
                imageHeight={70}
                contentCenter
              />
            </div>
          ))}
        </div>
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            accountType:
              isStudentAmbassador || isAlumniAmbassador
                ? accountTypeOptions.filter(
                    (option) => option.value === user.account_type
                  )[0]
                : { label: user.account_type, value: user.account_type },
            email: user.email ?? "",
            profileImage: user.profile_image_url ?? "",
            firstName: user.first_name ?? "",
            lastName: user.last_name ?? "",
            dob: user.dob ?? "",
            nationality: user.nationality
              ? { label: user.nationality, value: user.nationality }
              : null,
            intro: user.intro ?? "",
            degree: user.degree
              ? { label: user.degree, value: user.degree }
              : null,
            major: user.major ? { label: user.major, value: user.major } : null,
            graduationYear: user.graduation_year
              ? { label: user.graduation_year, value: user.graduation_year }
              : null,
            accessibilityMode: user.accessibility_help ?? false,
            country_code: user.country_code
              ? user.country_code.replace("+", "")
              : "",
            phone_number: user.phone_number
              ? (user.country_code ? user.country_code.replace("+", "") : "") +
                user.phone_number
              : "",
          }}
          validate={validate}
          onSubmit={handleSave}
        >
          {({ errors, touched, handleBlur, values, setFieldValue }) => (
            <Form>
              {/* Profile Header */}
              <div className={cx("o-profile-header__wrapper")}>
                <div className={cx("o-profile-header__title")}>
                  <h1>My Profile</h1>
                </div>
                <div className={cx("o-profile-header__control-wrapper")}>
                  {globalUser.can_invite &&
                    user.invitee_list.length < INVITEE_LIMIT && (
                      <a
                        href="/#"
                        className={cx(
                          "o-profile-header__control-wrapper__invite-link"
                        )}
                        onClick={(e) => {
                          e?.preventDefault && e.preventDefault();
                          setShowInviteModal(true);
                        }}
                      >
                        {isProspect ? "Invite parent" : "Invite prospect"}
                      </a>
                    )}

                  <Button
                    modifier={ButtonModifier.PROFILE_HEADER_PRIMARY}
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </div>
              {/* Profile login */}
              <div className={cx("o-profile-login-wrapper")}>
                <Card>
                  <div className={cx("o-profile-login-header")}>
                    <div className={cx("o-profile-login-header__title")}>
                      <h2>Log in</h2>
                    </div>
                  </div>
                  <div className={cx("o-profile-login-form")}>
                    {(isAlumniAmbassador || isStudentAmbassador) && (
                      <div className={cx("o-profile-login-form__field")}>
                        <div
                          className={cx([
                            "o-profile-login-form__field__input",
                            "o-profile-login-form__field__select",
                          ])}
                        >
                          <MuiSelect
                            label="Account type*"
                            placeholder="Account type"
                            value={
                              accountTypeOptions
                                ? accountTypeOptions.find(
                                    (option) =>
                                      option.value === values.accountType?.value
                                  )
                                : ""
                            }
                            options={accountTypeOptions}
                            onChange={(value) => {
                              setFieldValue("accountType", value);
                              // Reset graduation year
                              setFieldValue("graduationYear", null);
                            }}
                            justifyContent
                          />
                        </div>
                      </div>
                    )}
                    <div className={cx("o-profile-login-form__field")}>
                      <div className={cx("o-profile-login-form__field__label")}>
                        <h5>Email*</h5>
                        <span
                          className={cx(
                            "o-profile-login-form__field__label_edit"
                          )}
                          onClick={() => setShowEditEmailModal(true)}
                        >
                          Edit
                        </span>
                      </div>
                      <div className={cx("o-profile-login-form__field__input")}>
                        <MuiTextbox
                          type="email"
                          value={user.email ?? ""}
                          name="emailDisabled"
                          placeholder="Enter your email"
                          disabled
                        />
                      </div>
                    </div>
                    <div className={cx("o-profile-login-form__field")}>
                      <div className={cx("o-profile-login-form__field__label")}>
                        <h5>Password*</h5>
                        <span
                          className={cx(
                            "o-profile-login-form__field__label_edit"
                          )}
                          onClick={() => setShowChangePasswordModal(true)}
                        >
                          Edit
                        </span>
                      </div>
                      <div className={cx("o-profile-login-form__field__input")}>
                        <MuiTextbox
                          type="password"
                          value={"*****"}
                          name="passwordDisabled"
                          placeholder="Enter your password"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
              {/* Profile Personal */}
              <div className={cx("o-profile-personal-wrapper")}>
                <Card>
                  <div className={cx("o-profile-personal-header")}>
                    <div className={cx("o-profile-personal-header__title")}>
                      <h2>Personal</h2>
                    </div>
                  </div>
                  <div className={cx("o-profile-personal-form")}>
                    {((isProspect && profilePictureSetting?.is_visible) ||
                      isParent ||
                      isStudentAmbassador ||
                      isAlumniAmbassador) && (
                      <div
                        className={cx("o-profile-personal-form__file-upload")}
                      >
                        <div
                          className={cx(
                            "o-profile-personal-form__file-upload-header"
                          )}
                        >
                          <h5>
                            Upload profile picture
                            {isProspect && profilePictureSetting?.is_mendatory
                              ? "*"
                              : " (optional)"}
                          </h5>
                        </div>
                        <FileUpload
                          maxFileSize={5}
                          defaultBackgroundImage={values.profileImage}
                          onSelectSuccess={(file) =>
                            setFieldValue("profileImage", file)
                          }
                          onRemoveSuccess={(status) =>
                            handleProfileImageRemove(status)
                          }
                          userName={user.name}
                          loader={isUpdatingProfileImage}
                        />
                        {touched.profileImage && errors.profileImage && (
                          <span className="p-error-profile">
                            {errors.profileImage}
                          </span>
                        )}
                      </div>
                    )}
                    <div className={cx("o-profile-personal-form__field")}>
                      <div
                        className={cx("o-profile-personal-form__field__input")}
                      >
                        <MuiTextbox
                          onBlur={handleBlur}
                          value={values.firstName}
                          onChange={(e) =>
                            setFieldValue(
                              "firstName",
                              getCapitalizedHeader(e.target.value)
                            )
                          }
                          name={"firstName"}
                          label="First name*"
                          placeholder={"First name"}
                          error={
                            touched.firstName && errors.firstName
                              ? errors.firstName
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className={cx("o-profile-personal-form__field")}>
                      <div
                        className={cx("o-profile-personal-form__field__input")}
                      >
                        <MuiTextbox
                          onBlur={handleBlur}
                          value={values.lastName}
                          onChange={(e) =>
                            setFieldValue("lastName", e.target.value)
                          }
                          name={"lastName"}
                          label="Last name*"
                          placeholder={"Last name"}
                          error={
                            touched.lastName && errors.lastName
                              ? errors.lastName
                              : ""
                          }
                        />
                      </div>
                    </div>
                    {dobSetting?.is_visible && isProspect && (
                      <div className={cx("o-profile-personal-form__field")}>
                        <div
                          className={cx(
                            "o-profile-personal-form__field__input"
                          )}
                        >
                          <CustomDatePicker
                            onBlur={handleBlur}
                            value={values.dob}
                            onChange={(val) => setFieldValue("dob", val)}
                            name={"dob"}
                            label={
                              dobSetting?.is_mendatory
                                ? "Birth date*"
                                : "Birth date"
                            }
                            error={touched.dob && errors.dob ? errors.dob : ""}
                            maxDate={moment()}
                            disableTextInput
                          />
                        </div>
                      </div>
                    )}
                    {(isAlumniAmbassador ||
                      isStudentAmbassador ||
                      (isProspect && nationalitySetting?.is_visible)) && (
                      <div className={cx("o-profile-personal-form__field")}>
                        <div
                          className={cx([
                            "o-profile-login-form__field__input",
                            "o-profile-login-form__field__select",
                          ])}
                        >
                          <MuiSelect
                            onBlur={handleBlur}
                            label={
                              isAlumniAmbassador ||
                              isStudentAmbassador ||
                              (isProspect && nationalitySetting?.is_mendatory)
                                ? "Nationality*"
                                : "Nationality"
                            }
                            placeholder="Select nationality"
                            value={
                              nationalityOptions
                                ? nationalityOptions.find(
                                    (option) =>
                                      option.value === values.nationality?.value
                                  )
                                : ""
                            }
                            options={nationalityOptions}
                            onChange={(value) =>
                              setFieldValue("nationality", value)
                            }
                            justifyContent
                            error={
                              touched.nationality && errors.nationality
                                ? errors.nationality
                                : ""
                            }
                          />
                        </div>
                      </div>
                    )}

                    {isProspect && phoneNoSetting?.is_visible && (
                      <div className={cx("o-profile-personal-form__field")}>
                        <div
                          className={cx(["o-profile-login-form__field__input"])}
                        >
                          <PhoneNumberSelect
                            name={"phone_number"}
                            label={`Your phone number${
                              phoneNoSetting?.is_mendatory ? "*" : ""
                            }`}
                            placeholder={"Enter phone number"}
                            value={values.phone_number}
                            onChange={(phone_number, country_code) => {
                              setFieldValue("phone_number", phone_number);
                              setFieldValue("country_code", country_code);
                            }}
                            onBlur={handleBlur}
                            error={
                              touched.phone_number && errors.phone_number
                                ? errors.phone_number
                                : ""
                            }
                          />
                        </div>
                      </div>
                    )}

                    {!isProspect && !isParent && (
                      <div className={cx("o-profile-personal-form__field")}>
                        <div
                          className={cx(
                            "o-profile-personal-form__field__textarea"
                          )}
                        >
                          <MuiTextbox
                            isMultiline
                            rows={2}
                            onBlur={handleBlur}
                            value={values.intro}
                            onChange={(e) =>
                              setFieldValue("intro", e.target.value)
                            }
                            name={"introduction"}
                            label="Introduction*"
                            placeholder="Give a short intro about who you are. This will be visible to students in the network portal."
                            error={errors.intro ?? ""}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              </div>
              {/* Profile Education */}
              {(isAlumniAmbassador ||
                isStudentAmbassador ||
                (isProspect &&
                  (degreeSetting?.is_visible ||
                    majorSetting?.is_visible ||
                    enrollmentYearSetting?.is_visible))) && (
                <div className={cx("o-profile-education-wrapper")}>
                  <Card>
                    <div className={cx("o-profile-education-header")}>
                      <div className={cx("o-profile-education-header__title")}>
                        <h2>Education</h2>
                      </div>
                    </div>
                    <div className={cx("o-profile-education-form")}>
                      {(isAlumniAmbassador ||
                        isStudentAmbassador ||
                        (isProspect && degreeSetting?.is_visible)) && (
                        <div className={cx("o-profile-education-form__field")}>
                          <div
                            className={cx([
                              "o-profile-login-form__field__input",
                              "o-profile-login-form__field__select",
                            ])}
                          >
                            <MuiSelect
                              onBlur={handleBlur}
                              label={
                                isProspect && degreeSetting?.is_mendatory
                                  ? "Desired degree*"
                                  : isProspect && !degreeSetting?.is_mendatory
                                  ? "Desired degree"
                                  : "Degree*"
                              }
                              placeholder="Select degree"
                              value={
                                degreeOptions
                                  ? degreeOptions.find(
                                      (option) =>
                                        option.value === values.degree?.value
                                    )
                                  : ""
                              }
                              options={degreeOptions}
                              onChange={(value) =>
                                setFieldValue("degree", value)
                              }
                              justifyContent
                              error={
                                touched.degree && errors.degree
                                  ? errors.degree
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      )}
                      {(isAlumniAmbassador ||
                        isStudentAmbassador ||
                        (isProspect && majorSetting?.is_visible)) && (
                        <div className={cx("o-profile-education-form__field")}>
                          <div
                            className={cx(
                              "o-profile-education-form__field__input"
                            )}
                          >
                            <MuiSelect
                              onBlur={handleBlur}
                              name="major"
                              label={
                                isProspect && majorSetting?.is_mendatory
                                  ? "Desired major*"
                                  : isProspect && !majorSetting?.is_mendatory
                                  ? "Desired major"
                                  : "Major*"
                              }
                              placeholder="Select major"
                              value={
                                majorOptions === null
                                  ? ""
                                  : majorOptions?.includes(
                                      (option) =>
                                        option.value === values.major?.value
                                    )
                                  ? majorOptions?.find(
                                      (option) =>
                                        option.value === values.major?.value
                                    )
                                  : {
                                      label: values.major?.label,
                                      value: values.major?.value,
                                    }
                              }
                              options={majorOptions}
                              onChange={(value) =>
                                setFieldValue("major", value)
                              }
                              justifyContent
                              addText="Add major"
                              editable={true}
                              error={
                                touched.major && errors.major
                                  ? errors.major
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      )}
                      {values.accountType.value === "student" ||
                      (values.accountType.value === "prospect_user" &&
                        enrollmentYearSetting?.is_visible) ? (
                        <div className={cx("o-profile-education-form__field")}>
                          <div
                            className={cx([
                              "o-profile-login-form__field__input",
                              "o-profile-login-form__field__select",
                            ])}
                          >
                            <MuiSelect
                              onBlur={handleBlur}
                              label={
                                values.accountType.value === "student"
                                  ? "Graduation Year*"
                                  : enrollmentYearSetting?.is_mendatory
                                  ? "Expected enrollment year*"
                                  : "Expected enrollment year"
                              }
                              placeholder={
                                values.accountType.value === "student"
                                  ? "Graduation year"
                                  : "Enrollment year"
                              }
                              value={
                                expectedEnrollmentYearOptions
                                  ? expectedEnrollmentYearOptions.find(
                                      (option) =>
                                        option.value ===
                                        values.graduationYear?.value
                                    )
                                  : ""
                              }
                              options={expectedEnrollmentYearOptions}
                              onChange={(value) =>
                                setFieldValue("graduationYear", value)
                              }
                              justifyContent
                              error={
                                touched.graduationYear && errors.graduationYear
                                  ? errors.graduationYear
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      ) : values.accountType.value !== "student" &&
                        values.accountType.value !== "prospect_user" ? (
                        <div className={cx("o-profile-education-form__field")}>
                          <div
                            className={cx([
                              "o-profile-login-form__field__input",
                              "o-profile-login-form__field__select",
                            ])}
                          >
                            <MuiSelect
                              onBlur={handleBlur}
                              label="Graduation Year*"
                              placeholder="Graduation year"
                              value={
                                graduationYearOptions
                                  ? graduationYearOptions.find(
                                      (option) =>
                                        option.value ===
                                        values.graduationYear?.value
                                    )
                                  : ""
                              }
                              options={graduationYearOptions}
                              onChange={(value) =>
                                setFieldValue("graduationYear", value)
                              }
                              justifyContent
                              error={
                                touched.graduationYear && errors.graduationYear
                                  ? errors.graduationYear
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Card>
                </div>
              )}
              {/* Invites */}
              {user.invitee_list &&
                user.invitee_list.length > 0 &&
                (isProspect || isParent) && (
                  <div className={cx("o-profile__invite")}>
                    <Card>
                      <div className={cx("o-profile__invite__header")}>
                        <div className={cx("o-profile__invite__header__title")}>
                          <h2>Invites</h2>
                        </div>
                      </div>
                      <div className={cx("o-profile__invite__table")}>
                        <div
                          className={cx("o-profile__invite__table__row", {
                            [`o-profile__invite__table__row--header`]: true,
                          })}
                        >
                          <span
                            className={cx(
                              "o-profile__invite__table__row__column"
                            )}
                          >
                            Name
                          </span>
                          <span
                            className={cx(
                              "o-profile__invite__table__row__column"
                            )}
                          >
                            Email
                          </span>
                          <span
                            className={cx(
                              "o-profile__invite__table__row__column"
                            )}
                          >
                            Status
                          </span>
                        </div>
                        {user.invitee_list.map((rec) => (
                          <div
                            key={rec.email}
                            className={cx("o-profile__invite__table__row", {
                              [`o-profile__invite__table__row--content`]: true,
                            })}
                          >
                            <span
                              className={cx(
                                "o-profile__invite__table__row__column"
                              )}
                            >
                              <span> Name</span>
                              {rec.first_name} {rec.last_name}
                            </span>
                            <span
                              className={cx(
                                "o-profile__invite__table__row__column"
                              )}
                            >
                              <span> Email</span>
                              {rec.email}
                            </span>
                            <span
                              className={cx(
                                "o-profile__invite__table__row__column"
                              )}
                            >
                              <span> Status</span>
                              {getCapitalizedHeader(rec.status)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </Card>
                  </div>
                )}
              {/* Profile footer */}
              <div className={cx("o-profile-actions-bar")}>
                <div className={cx("o-profile-actions-bar__left")}>
                  <div className={cx("o-profile-actions-bar__left__preview")}>
                    <div
                      className={cx(
                        "o-profile-actions-bar__left__preview__icon"
                      )}
                    >
                      <ViewIcon />
                    </div>
                    <div
                      className={cx(
                        "o-profile-actions-bar__left__preview__text"
                      )}
                      onClick={() => setShowPreviewProfile(true)}
                    >
                      Preview profile
                    </div>
                  </div>
                </div>
                <div
                  className={cx("o-profile-actions-bar__accessibility-mode")}
                >
                  <ToggleSwitch
                    isChecked={values.accessibilityMode}
                    setIsChecked={() =>
                      setFieldValue(
                        "accessibilityMode",
                        !values.accessibilityMode
                      )
                    }
                    label="Enable accessibility mode"
                  />
                  <span>Enable accessibility mode</span>
                </div>
                <div className={cx("o-profile-actions-bar__right-actions")}>
                  <div className={cx("o-profile-actions-bar__left__close")}>
                    <div
                      className={cx("o-profile-actions-bar__left__close__text")}
                      onClick={() => setShowCloseAccountModal(true)}
                    >
                      Close account
                    </div>
                  </div>
                  <Button
                    modifier={ButtonModifier.PROFILE_HEADER_SECONDARY}
                    onClick={() => setShowSignoutModal(true)}
                    type={"button"}
                  >
                    Sign Out
                  </Button>
                </div>
              </div>
              <div className={cx("o-profile__foot-note")}>
                <p>All mandatory fields are marked with an asterisk (*).</p>
              </div>
              {/* Edit email modal */}
              <EditEmailModal
                showEditEmailModal={showEditEmailModal}
                setShowEditEmailModal={setShowEditEmailModal}
                handleOnSignout={handleOnSignout}
              />

              {/* Edit password modal */}
              <EditPasswordModal
                showChangePasswordModal={showChangePasswordModal}
                setShowChangePasswordModal={setShowChangePasswordModal}
              />

              {/* Profile preview modal */}
              {values && (
                <PreviewProfileModal
                  data={values}
                  showPreviewProffile={showPreviewProffile}
                  setShowPreviewProfile={setShowPreviewProfile}
                  permissions={permissions}
                />
              )}

              {/* Sign out modal */}
              <SignOutModal
                showModal={showSignoutModal}
                closeModal={() => setShowSignoutModal(false)}
                handleOnSignout={handleOnSignout}
              />

              {/* Close Account Modal */}
              <Modal
                show={showCloseAccountModal}
                onClose={() => setShowCloseAccountModal(false)}
                maxWidth={480}
              >
                <ModelHeader
                  title={"Are you sure you want to close your account?"}
                />
                <ModalBody>
                  <div className={cx("o-profile-close-account-modal")}>
                    <div className={cx("submit-button")}>
                      <Button
                        onClick={handleCloseAccount}
                        modifier={ButtonModifier.PROFILE_SUBMIT}
                      >
                        Confirm
                      </Button>
                    </div>

                    <a
                      href="/cancel"
                      className={cx("cancel-link")}
                      onClick={(e) => {
                        e?.preventDefault && e.preventDefault();
                        setShowCloseAccountModal(false);
                      }}
                    >
                      Cancel
                    </a>
                  </div>
                </ModalBody>
              </Modal>

              {showInviteModal && (
                <ParentProspectInviteForm
                  showModal={showInviteModal}
                  closeModal={() => setShowInviteModal(false)}
                  role={isProspect ? "parent" : "prospect"}
                  onUserInvited={handleUserInvite}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default memo(Profile);
